<template>
  <div class="feedMechanism">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <el-form-item label="所在地区：">
        <cascader-area
          :country="queryInfo.demandCountry"
          :province="queryInfo.demandProvince"
          :city="queryInfo.demandCity"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"
        />
      </el-form-item>
      <el-form-item label="日期：">
        <data-time @dateChange="dateClick" />
      </el-form-item>
      <el-form-item label="行业类别：">
        <dic-tag-button
          :code.sync="queryInfo.industryName"
          query-name="industryName"
          name="行业类别"
          tag="行业"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="领域类别：">
        <dic-tag-button
          :code.sync="queryInfo.fieldIdName"
          query-name="fieldIdName"
          name="领域类别"
          tag="领域"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item,index) in tags"
          :key="index+item.name"
          @close="closeTag(index,item.tagName)"
        >
          {{item.name}}：{{queryInfo[item.tagName]?queryInfo[item.tagName]:'不限'}}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength')&&item.valLength>0"
          >+{{item.valLength}}</span>
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="inputVal">
        <el-input placeholder="请输入机构名称" v-model="queryInfo.queryConditions" class="lager-input">
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>

      <el-table
        class="ly_table"
        :data="feedMechanismTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        :default-sort="{prop: 'date', order: 'descending'}"
        @sort-change="sortChange"
      >
        <el-table-column prop="supplierName" label="供应商名称" align="center" />
        <el-table-column prop="count" label="项目个数" sortable='custom' align="center">
          <template v-slot="{row}">
            <el-button type="text" @click="viewItem(row)">{{row.count}}</el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column label="所在地区" align="center">
          <template v-slot="{row}">
            {{row.supplierCountry?row.supplierCountry:''}}
            {{row.supplierProvince?'-'+row.supplierProvince:''}}
            {{row.supplierCity?'-'+row.supplierCity:''}}
          </template>
        </el-table-column>
        <el-table-column prop="supplierType" label="供应商类型" align="center" />
      </el-table>

      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50,100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { getProjectSupplierMode } from '@/api/numberProject'
import CascaderArea from '@/components/CascaderArea.vue'
import DataTime from "../../Product/com/dataTime.vue";
import DicTagButton from '@/components/DicTagButton.vue'
import DicRadioButton from '@/components/DicRadioButton.vue'
import ButtonTable from '@/components/ButtonTable.vue'
import { pagination } from '@/mixins/pagination'
export default {
  name: 'FeedMechanism',

  mixins: [pagination],

  components: {
    DicTagButton,
    DicRadioButton,
    ButtonTable,
    CascaderArea,
    DataTime,
  },

  data() {
    return {
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        order:1,
        demandCountry: '', // 国家
        demandProvince: '', // 省份
        demandCity: '', // 地区
        years: null, // 年
        month: null, // 月
        day: null, // 日
        columnName : "update_time",
        projectIndustryType: [], // 行业类别
        industryName: '',
        projectFieldType: [], // 领域类别
        fieldIdName: '',
      },
      feedMechanismTable: [],
      tags: [
        {
          name: '行业类别',
          tagName: 'industryName',
          valLength: 0,
        },
        {
          name: '领域类别',
          tagName: 'fieldIdName',
          valLength: 0,
        },
      ],
    }
  },

  created() {
    this.search()
  },

  methods: {
    onChange() {},
    async search() {
      const res = await getProjectSupplierMode(this.queryInfo)

      if (res.code === 200) {
        this.feedMechanismTable = res.data.list
      }

      this.total = res.data.total
    },
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = []

      if (!this.tags.some((item) => item.tagName === queryName)) {
        if (name === '行业类别' || name === '领域类别') {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0,
          })
        } else {
          this.tags.push({
            name,
            tagName: queryName,
          })
        }
      }

      // 拿到name在tags的下标
      let result
      result = this.tags.findIndex((el) => {
        return el.name === name
      })

      switch (name) {
        case '行业类别':
          this.queryInfo.projectIndustryType = [val]

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length

            childrenVal = checkboxVal

            this.queryInfo.projectIndustryType =
              this.queryInfo.projectIndustryType.concat(childrenVal)
          } else {
            this.tags[result].valLength = 0
          }

          break

        case '领域类别':
          this.queryInfo.projectFieldType = [val]

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length

            childrenVal = checkboxVal

            this.queryInfo.projectFieldType =
              this.queryInfo.projectFieldType.concat(childrenVal)
          } else {
            this.tags[result].valLength = 0
          }

          break

        default:
          break
      }

      this.search()
    },
    closeTag(index, name) {
      this.tags.splice(index, 1)

      switch (name) {
        case 'industryName':
          this.queryInfo.industryName = ''
          this.queryInfo.projectIndustryType = []
          break
        case 'fieldIdName':
          this.queryInfo.fieldIdName = ''
          this.queryInfo.projectFieldType = []
          break
        default:
          this.queryInfo[name] = ''
          break
      }
      this.search()
    },
    viewItem(row) {
      let text = this.$router.resolve({
        path: "/numberProject/viewFeedMechanism",
        query: {
          feedParams: row.id,
          feedParamsName:row.supplierName,
          supplierLinkman:row.supplierLinkman,
          supplierPhone:row.supplierPhone
        },
      });
      window.open(text.href, "_blank");
      // this.$router.push({
      //   name: 'ViewFeedMechanism',
      //   query: {
      //     feedParams: row.id,
      //     feedParamsName:row.supplierName,
      //     supplierLinkman:row.supplierLinkman,
      //     supplierPhone:row.supplierPhone
      //   },
      // })
    },
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.years = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
        this.search();
      } else {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
        this.search();
      }
    },
    searchCountry(val) {
      this.queryInfo.demandCountry = val
      this.search()
    },
    searchProvince(val) {
      this.queryInfo.demandProvince = val
      this.search()
    },
    searchCity(val) {
      if (val != '北京' && val != '天津' && val != '上海' && val != '重庆') {
        this.queryInfo.demandCity = val
        this.search()
      }
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "count") {
        this.queryInfo.columnName = "count";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.search();
    },
  },
}
</script>

<style lang="less" scoped>
.feedMechanism {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .inputVal {
    display: flex;
    justify-content: flex-end;
  }
}
/deep/ .filterInfo .el-form-item__content{
  padding: 0;
}
</style>
